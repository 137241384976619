<script setup lang="ts">
import type { FieldError } from '~/utils/error/YscApiError'

export interface YscFormProps {
  id: string
  submitAction?: () => Promise<void> | void
  noStyle?: boolean
}

const props = withDefaults(defineProps<YscFormProps>(), {
  id: '',
  submitAction: () => () => {
    console.warn('[YscForm] no submitAction provided as props')
    return Promise.resolve()
  },
  noStyle: false,
})

const formEl = ref()
const { setSendingForm } = inject(setSendingFormKey, { setSendingForm: () => {} })

const onSubmit = async () => {
  if (!useCheckValidity({ formEl })) {
    return
  }

  setSendingForm(true)
  await props.submitAction()
  setSendingForm(false)
}

const errors = ref<FieldError[]>([])
provide('ysc-form-errors', errors)

const setErrors = (errs: FieldError[]) => {
  errors.value = errs
}

const clearErrors = (path?: string) => {
  if (path) {
    errors.value = errors.value.filter(err => err.label.text !== path)
  }
  else {
    errors.value = []
  }
}

defineExpose({
  setErrors,
  clearErrors,
})
</script>

<template>
  <form
    :id="props.id"
    ref="formEl"
    :class="{ 'space-y-4': !noStyle }"
    @submit.stop.prevent="onSubmit"
  >
    <slot />
  </form>
</template>
