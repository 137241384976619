export function useCheckValidity({ formEl }: { formEl: MaybeRef<HTMLFormElement> }) {
  if (import.meta.server) {
    return false
  }

  const formElRef = toRef(formEl)
  // formRef can be a component ref
  const formElement = formElRef.value.$el ?? formElRef.value
  if (!formElement.checkValidity()) {
    formElRef.value.$el.reportValidity()
    return false
  }

  return true
}
